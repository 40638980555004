import MiniSearch from 'minisearch';

export class Search extends MiniSearch {
    constructor(locations) {
        super({
            fields: [
                'city_name',
                'region_name',
            ],
            storeFields: [
                'city_id',
                'city_name',
                'region_id',
                'region_name',
                'cities',
            ],
        });

        this.locations = locations;
        this.addAll();
    }

    addAll() {
        const documents = [];

        Object.values(this.locations)
            .forEach((region) => {
                region.cities.forEach((city) => {
                    documents.push({
                        id: `${city.name}, ${region.name}`,
                        city_id: city.id,
                        city_name: city.name,
                        region_id: region.id,
                        region_name: region.name,
                        cities: region.cities,
                    });
                });
            });

        super.addAll(documents);
    }

    search(query) {
        return super
            .search(query, {
                prefix: true,
            });
    }
}
