<template lang="pug">
q-input.full-width(
    ref="textField"
    :bg-color="backgroundColor"
    :class="{ 'filled': filled, 'border-none': !border }"
    :dense="dense"
    :standout="standout"
    :rounded="rounded"
    :model-value="shadowValue"
    :error="error"
    :error-message="error_message"
    :placeholder="placeholder"
    :rules="rules"
    :lazy-rules="lazyRules"
    :mask="mask"
    :type="type"
    :loading="loading"
    :disable="disable"
    :input-class="input_class"
    @update:model-value="input"
    @keyup.enter="enter"
    @blur="blur")
    template(v-slot="prepend")
        slot(name="prepend")
    template(v-slot:label)
        span(v-if="label")
            | {{ label }}
    template(v-slot:append)
        slot(name="append")
    template(v-slot:hint)
        slot(name="hint")
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
    name: 'TextField',
    props: {
        backgroundColor: {
            type: String,
            default: () => 'secondary',
        },
        dense: {
            type: Boolean,
            default: () => true,
        },
        standout: {
            type: Boolean,
            default: () => true,
        },
        rounded: {
            type: Boolean,
            default: () => false,
        },
        value: {
            type: String,
            default: () => null,
        },
        label: {
            type: String,
            default: () => '',
        },
        error: {
            type: Boolean,
            default: () => false,
        },
        error_message: {
            type: String,
            default: () => '',
        },
        placeholder: {
            type: String,
            default: () => '',
        },
        type: {
            type: String,
            default: () => 'text',
        },
        mask: {
            type: String,
            default: () => '',
        },
        input_class: {
            type: String,
            default: () => '',
        },
        fillMask: {
            type: String,
            default: () => '',
        },
        rules: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        disable: {
            type: Boolean,
            default: () => false,
        },
        filled: {
            type: Boolean,
            default: () => false,
        },
        lazyRules: {
            type: Boolean,
            default: () => true,
        },
        reg_exp: {
            type: RegExp,
            default: () => null,
        },
        border: {
            type: Boolean,
            default: () => true,
        },
    },
    setup(props, { emit }) {
        const shadowValue = ref('');
        const textField = ref(null);

        watch(() => props.value, (value) => {
            shadowValue.value = value;
            textField.value.innerValue = value;
        });

        onMounted(() => {
            if (props.value) {
                shadowValue.value = props.value;
            }
        });

        const blur = () => {
            textField.value.blur();
        };
        const focus = () => {
            textField.value.focus();
        };
        const input = (value) => {
            if (props.reg_exp && !props.reg_exp.test(value)) {
                return;
            }

            emit('input', value);
        };
        const enter = () => {
            emit('enter');
        };
        const validate = () => {
            textField.value.validate();
        };
        const reset = () => {
            textField.value.resetValidation();
        };

        return {
            shadowValue,
            textField,
            blur,
            focus,
            input,
            enter,
            validate,
            reset,
        };
    },
};
</script>

<style lang="scss" scoped>
.q-field.q-field--disabled :deep(.q-field__control > div) {
    opacity: 1 !important;
}

.q-field :deep(.q-field__control) {
    height: 44px;
    box-shadow: none;
    border: 1px solid $grey-13;

    &::before {
        background: transparent;
    }

    .q-field__label {
        color: $grey-1;
        font-size: 1.1em;
        line-height: 1.6em;
    }

    ::placeholder {
        color: $grey-12;
    }
}

.q-field :deep(.q-field__bottom) {
    padding-top: 2px;
}

.q-field.q-field--focused :deep(.q-field__control) {
    border: 1px solid $positive;

    .q-field__native {
        color: $primary !important;
    }

    .q-field__append,
    .q-field__prepend {
        color: $grey-4;
    }
}

.q-field.q-field--error :deep(.q-field__control) {
    border: 1px solid $negative !important;

    .q-field__label,
    .q-field__native {
        color: $negative;
    }
}

.q-field--with-bottom {
    padding: 0;
}

.q-field.q-field--disabled :deep(.q-field__control) {
    .q-field__append,
    .q-field__prepend {
        height: 100%;
    }

    .q-field__native {
        color: $grey-1;
    }
}

.q-field.q-field--rounded :deep(.q-field__control),
.q-field.q-field--rounded.q-field--error :deep(.q-field__control) {
    border: 1px solid $grey-6;

    ::placeholder {
        color: $grey-13;
    }
}

.q-field.border-none :deep(.q-field__control),
.q-field.border-none.q-field--focused :deep(.q-field__control) {
    border: 1px solid $grey-3;
}

.q-field.filled :deep(.q-field__control),
.q-field.filled.q-field--focused :deep(.q-field__control),
.q-field.filled.q-field--error :deep(.q-field__control) {
    border: none;
}

.q-field.filled :deep(.q-field__control),
.q-field.filled.q-field--error :deep(.q-field__control ) {
    border-radius: $generic-border-radius;
    border-bottom: 1px solid $grey-2;

    ::placeholder {
        color: $grey-13;
    }
}

.q-field.filled.q-field--focused :deep(.q-field__control) {
    border-bottom: 1px solid $grey-2;

    ::placeholder {
        color: $secondary;
    }
}
</style>

