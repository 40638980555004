<template lang="pug">
q-dialog(
    full-width
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down"
    ref="dialog"
    @hide="query = ''")
    .row.justify-start.full-width.bg-secondary.b-radius-0.q-pa-md.relative-position.city-form-mobile.text-primary(v-if="currentRegion")
        q-btn.absolute-top-right.z-top.q-ma-sm(
            flat
            round
            dense
            v-close-popup)
            q-icon(
                size="xs"
                name="etabl:cross"
                color="grey")
        .full-width.relative-position(v-show="query === ''")
            .column
                .font-16.text-weight-bold
                    | Выберите регион
                .q-pl-md
                    template(v-for="(region, key) in locations")
                        .font-14.text-weight-regular.q-mt-sm(
                            :class="currentRegion.id === key ? 'text-positive' : ''"
                            @click="currentRegion = region") {{ region.name }}
            .column.q-mt-md
                .font-16.text-weight-bold
                    | Населённый пункт
                q-scroll-area.q-pl-md(style="height: 300px" :thumb-style="{display: 'none'}")
                    template(v-for="city in currentRegion.cities")
                        .font-14.text-weight-regular.q-mt-sm(
                            :class="currentCity?.id === city?.id ? 'text-positive' : ''"
                            @click="changeCurrentCity(city)") {{ city.name }}
.row.items-center.q-mt-md(v-if="currentCity && menu")
    q-icon.q-mt-xs.q-mr-md.q-ml-xs(
        size="24px"
        name="etabl:marker"
        color="positive")
    .text-primary.cursor-pointer(@click="show") {{ currentCity.name }}
.row.items-currentRegion.items-center(v-if="currentCity && basket")
    .text-primary.cursor-pointer.text-weight-medium(@click="show") {{ currentCity.name }}
    q-icon.q-ml-sm(
        size="14px"
        name="etabl:edit"
        color="positive")
.row.items-center(v-if="currentCity && home")
    .text-positive.text-weight-medium(@click="show") {{ currentCity.name }}
base-btn.justify-center.items-center.close-button(
    v-if="button"
    label="Нет, другой"
    loading_color="positive"
    text_color="positive"
    color="secondary"
    border="1px solid #0CA551"
    height="30"
    font_size="13"
    bradius="16"
    @click="show"  
)
.column.q-ma-md(v-if="currentCity && drugstores")
    .row.items-center
        .font-14.text-primary
            | Регион
        .font-16.q-ml-sm.dashed-link(@click="show")
            | {{ currentRegion?.name }}
    .row.items-center.q-mt-xs
        .font-14.text-primary
            | Населенный пункт
        .font-16.q-ml-sm.dashed-link(@click="show")
            | {{ currentCity.name }}
.row.items-center.text-center.text-primary(v-if="currentCity && index" @click="show")
    span Населенный пункт:
    span.text-medium.q-ml-xs.q-mr-sm
        | {{ currentCity.name }}
    q-icon(
        size="20px"
        name="etabl:edit-mobile"
        color="positive")
.row.q-mx-md.q-pb-sm.q-mt-sm.items-center.text-center.text-primary(v-if="currentCity && indexPage" @click="show")
    q-icon(
        size="20px"
        name="etabl:location-marker"
        color="positive")
    .text-primary.cursor-pointer.q-ml-sm(@click="show") {{ currentCity.name }}
.column(v-if="currentCity && indexCard" @click="show")
    .text-weight-medium.text-primary
        | Ваш населенный пункт {{ currentCity.name }}?
    .text-weight-medium.text-positive.q-mt-sm
        | Изменить
</template>

<script>
import { sortCity } from 'assets/helpers/locations.js';
import { Search } from 'assets/helpers/minisearch.js';
import { useQuasar, LocalStorage } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TextField from 'components/BaseFields/TextField.vue';
import BaseBtn from 'components/Base/BaseBtn.vue';

export default {
    components: {
        TextField,
        BaseBtn,
    },
    props: {
        menu: {
            type: Boolean,
            default: () => true,
        },
        home: {
            type: Boolean,
            default: () => false,
        },
        button: {
            type: Boolean,
            default: () => false,
        },
        basket: {
            type: Boolean,
            default: () => false,
        },
        drugstores: {
            type: Boolean,
            default: () => false,
        },
        index: {
            type: Boolean,
            default: () => false,
        },
        indexPage: {
            type: Boolean,
            default: () => false,
        },
        indexCard: {
            type: Boolean,
            default: () => false,
        },
    },
    setup() {
        const init = ref(false);
        const store = useStore();
        const router = useRouter();
        const $q = useQuasar();
        const route = useRoute();
        
        const locations = ref({});
        const focus = ref(false);
        const loading = ref(false);
        const search = ref(null);
        const results = ref(null);
        const currentRegion = ref(null);
        const currentCity = ref({
            id: '17c304fd-27c4-11e6-a3e4-001e6784c60c',
            name: 'Белгород',
            slug: 'belgorod',
        });
        const dialog = ref(null);

        const cityName = computed(() => store.getters['cityForm/CITY_NAME']);
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        
        const query = ref('');
        watch(() => query.value, (value) => {
            value
                ? results.value = search.value.search(value)
                : results.value = null;
        });
        
        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);
        watch(() => userCityId.value, (value) => {
            if (value) {
                Object.values(locations.value)
                    .forEach((item) => {
                        const city = item.cities
                            ?.find(({ id }) => {
                                return id === userCityId.value;
                            });

                        if (city) {
                            currentRegion.value = item;
                            currentCity.value = city;
                        }
                    });
            }
        });

        const show = () => {
            dialog.value.show();
        };

        const hide = () => {
            dialog.value.hide();
        };

        const input = (value) => {
            query.value = value;
        };

        const changeCurrentCity = (city) => {
            currentCity.value = city;
            setLocation();
        };

        const setLocation = async (location) => {
            if (location) {
                query.value = '';

                currentRegion.value = {
                    region_id: location.regionId,
                    region_name: location.regionName,
                    cities: location.cities,
                };
                currentCity.value = {
                    id: location.id,
                    name: location.name,
                };
            }

            try {
                await store.dispatch('user/UPDATE_USER_CITY_ID', currentCity.value.id);
                if (process.env.CLIENT) {
                    LocalStorage.set('USER_CITY_ID', currentCity.value.id);
                }

                hide();

                if (userAuthorized.value) {
                    // await this.RESET_BASKET();
                }

                let redPath = '';

                if(currentCity.value.slug == 'belgorod') {
                    if(route.params.city != '') {
                        redPath = route.fullPath.replace(`/${route.params.city}/`, '/');
                    } else {
                        redPath = `/${currentCity.value.slug}${route.fullPath}`;
                    }
                    redPath = route.fullPath.replace(`/${route.params.city}/`, '/');
                        
                } else {
                    if(route.params.city != '') {
                        redPath = route.fullPath.replace(`/${route.params.city}/`, `/${currentCity.value.slug}/`);
                    } else {
                        redPath = `/${currentCity.value.slug}${route.fullPath}`;
                    }
                }

                await router.replace({ path: `${redPath}`, query: {...route.query}});

                LocalStorage.remove('hidePlash');
                
                if (process.env.CLIENT) 
                    window.location.reload(true);
            } catch (e) {
                console.error(error);

                $q.notify({
                    color: 'negative',
                    message: e.message,
                });
            }
        };

        onMounted(async () => {
            if (LocalStorage.getItem('USER_CITY_ID') && !userAuthorized.value) {
                store.commit('user/SET_USER_CITY_ID', LocalStorage.getItem('USER_CITY_ID'));
            }
            try {
                const response = await store.dispatch('cityForm/GET_LOCATIONS');

                let routeCity = null;

                if(route.params.city == '') {
                    response.results.forEach((region) => { 
                        region.cities.find((item) => {
                            if(item.slug == 'belgorod') {
                                routeCity = item;
                            }} ); });
                } else {
                    response.results.forEach((region) => { 
                        region.cities.find((item) => {
                            if(item.slug == route.params.city) {
                                routeCity = item;
                            }} ); });
                }

                if(routeCity.id != userCityId.value) setLocation(routeCity);


                if (response?.results?.length) {
                    response.results
                        .forEach((item) => {
                            locations.value[item.id] = item;

                            let region = sortCity(item);

                            const city = region.cities
                                ?.find(({ id }) => {
                                    return id === userCityId.value;
                                });

                            if (city) {
                                currentRegion.value = item;
                                currentCity.value = city;
                            };
                        });

                    search.value = new Search(locations.value);
                }

                init.value = true;
            } catch (error) {
                console.error(error);

                $q.notify({
                    color: 'negative',
                    message: error.message,
                });
            }
        });

        return {
            locations,
            cityName,
            currentRegion,
            userCityId,
            dialog,
            show,
            hide,
            input,
            loading,
            focus,
            setLocation,
            query,
            search,
            results,
            changeCurrentCity,
            currentCity,
            init,
        };
    },
};
</script>

<style lang="scss" scoped>
.city-form-mobile {
    border-radius: $mobile-border-radius $mobile-border-radius 0 0 !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    padding-bottom: calc(16px + constant(safe-area-inset-bottom));
    padding-bottom: calc(16px + env(safe-area-inset-bottom));

    &__sub {
        height: 80vh;
    }
}

.close-button {
    width: calc(50% - 8px) !important;
}
</style>
